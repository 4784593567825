
import { Vue, Component } from "vue-property-decorator";
import { mapMutations, mapGetters } from "vuex";
import * as types from "@/store/types";
import { LMap, LTileLayer, LMarker, LTooltip, LControl } from "vue2-leaflet";
import { FilterFormMap } from "@/interfaces";
import { TOKEN } from "@/services/auth/auth-service";
Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);
Vue.component("l-tooltip", LTooltip);
Vue.component("l-control", LControl);

@Component({
    components: {
        CardWidget: () => import("@/components/layout/CardWidget.vue"),
        FilterForm: () => import("@/pages/components/forms/FilterFormMap.vue")
    },
    methods: {
        ...mapMutations({
            setProgressBar: types.MUTATE_APP_PROGRESSBAR,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE
        })
    },
    computed: {
        ...mapGetters({
            userIdentifier: types.USER_IDENTIFIER
        })
    }
})
export default class Maps extends Vue {
    setProgressBar!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;
    showError(error: string) {
        this.setInfoMessage({ shown: true, text: error });
    }
    filterForm: FilterFormMap = {
        company: [],
        client: [],
        installation: [],
        valid: true
    };

    url = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
    attribution =
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors';
    zoom = 15;
    lat = 43.21938527663835;
    long = -2.027420505580564;
    center = [43.21938527663835, -2.027420505580564];
    markerLatLng = [43.21938527663835, -2.027420505580564];
    lugares: any[] = [];
    install: any = { cliente: "", instalacion: "" };
    showDialog = false;
    dialogKey = 0;

    onFilterUpdated() {
        if (Vue.$cookies.isKey(TOKEN)) {
            this.zoom = 11;
            this.lugares = this.filterForm.installation;
            // this.getStats();
        } else {
            this.$router.push("/signin");
        }
    }

    openDialog(install) {
        this.dialogKey = this.dialogKey + 1;
        this.install = install;
        this.showDialog = true;
    }

    instalacionMapaSeleccion(item) {
        this.showDialog = false;
        localStorage.setItem("installationFromMaps", JSON.stringify(item));
        this.$root.$emit("ResumenOnMap");
        window.location.replace("/#/resumen");
        // const url = window.open("/#/resumen", "_blank");
        // if (url) {
        //     url.focus();
        // }
    }

    instalacionesYMejoras(item) {
        this.showDialog = false;
        localStorage.setItem("installationFromMaps", JSON.stringify(item));
        this.$root.$emit("InstalacionOnMap");
        window.location.replace("/#/installation");
        // const url = window.open("/#/resumen", "_blank");
        // if (url) {
        //     url.focus();
        // }
    }

    get formatDialogCliente() {
        console.info("formateador", this.install.cliente);
        if (this.install.cliente) {
            return "";
        } else {
            return this.install.cliente.split(" - ")[1];
        }
    }

    get formatDialogInstalacion() {
        console.info("formateador", this.install.instalacion);
        if (this.install.cliente) {
            return "";
        } else {
            return this.install.cliente.split(" - ")[1];
        }
    }

    getStats() {
        let max = this.markerLatLng[1];
        let min = this.markerLatLng[1];
        if (this.lugares) {
            this.lat = this.markerLatLng[0];
            this.long = this.markerLatLng[1];
            for (const x in this.lugares) {
                this.lat = this.lat + Number(this.lugares[x].ubicacion[0]);
                this.long = this.long + Number(this.lugares[x].ubicacion[1]);
                if (Number(this.lugares[x].ubicacion[1]) > max) {
                    max = Number(this.lugares[x].ubicacion[1]);
                } else if (Number(this.lugares[x].ubicacion[1]) < min) {
                    min = Number(this.lugares[x].ubicacion[1]);
                }
            }
            this.lat = this.lat / (this.lugares.length + 1);
            this.long = this.long / (this.lugares.length + 1);
            this.center = [this.lat, this.long];
        }
        if (Math.abs(Math.abs(max - min)) >= 0.45) {
            this.zoom = 9;
        } else if (Math.abs(max - min) >= 0.3) {
            this.zoom = 10;
        } else if (Math.abs(max - min) >= 0.2) {
            this.zoom = 11;
        } else if (Math.abs(max - min) >= 0.1) {
            this.zoom = 12;
        } else if (Math.abs(max - min) >= 0.05) {
            this.zoom = 13;
        } else if (Math.abs(max - min) >= 0.05) {
            this.zoom = 14;
        } else {
            this.zoom = 15;
        }
    }
}
